<template>
	<div>
		<v-item-group
			v-model="selected"
			:multiple="multiple"
			:disabled="isDisabled"
		>
			<div
				class="mw-option-tile-wrapper"
				v-for="option in answerOptionsOrder"
				:key="option"
			>
				<v-item
					v-slot="{ active, toggle }"
					:value="answerOptions[option].value"
				>
					<v-card
						:class="active ? 'active' : ''"
						flat
						class="mw-option-tile"
						transparent
						@click="isDisabled ? warn() : toggle()"
					>
						{{ answerOptions[option].text }}
					</v-card>
				</v-item>
			</div>
		</v-item-group>
	</div>
</template>

<style lang="less">
@import "../../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "RadioTiles",
	props: {
		value: {},
		answerOptionsOrder: {}
	},
	data: () => {
		return {
			selected: false,
		};
	},
	inject: ["question", "answerOptions", "disabled", "multiple"],
	computed: {
		isDisabled() {
			return this.disabled || this.question.disable_input;
		},
	},
	watch: {
		selected() {
			this.$emit("update", this.selected);
		},
		value: {
			immediate: true,
			handler(data) {
				this.selected = data;
			},
		},
	},
	methods: {
		warn() {
			this.$emit("error");
		},
	},
};
//
</script>
"
